import Cookies from "universal-cookie";
import config from "../../config.json";
const env = process.env.REACT_APP_ENVIRONMENT || "stage";
const BACKEND_URL = config.api[env];

import axios from "axios";

const moment = require("moment");

export function login(email, pass, cb) {
  cb = arguments[arguments.length - 1];
  if (localStorage.jwtToken) {
    if (cb) {
      cb(true);
    }
    return;
  }
  console.log(env)

  axios
    .post(`${BACKEND_URL}/v1/boUser/login`, {
      email,
      password: pass,
    })
    .then(async ({ data }) => {
      const { result } = data;

      const returnValue = { login: true };

      if (
        result.user.firstLogin ||
        typeof result.user.firstLogin === "undefined"
      ) {
        localStorage.passwordToken = data.result.token;
        Object.assign(returnValue, { firstLogin: true, token: result.token });
      } else {
        Object.assign(returnValue, { firstLogin: false });
        localStorage.jwtToken = data.result.token;
        localStorage.expiration = moment().add(24, "hours").toISOString();
        localStorage.user = JSON.stringify(result.user);
      }

      try {
        const permissionsResponse = await axios.get(
          `${BACKEND_URL}/profile/access`,
          {
            headers: { Authorization: `Bearer ${data.result.token}` },
          }
        );

        localStorage.permissions = JSON.stringify(
          permissionsResponse.data.permissions
        );

        if (cb) {
          return cb(returnValue);
        }
      } catch (error) {
        console.error("Erro ao obter permissões:", error);
        if (cb) {
          return cb({ login: false });
        }
      }
    })
    .catch(() => {
      if (cb) {
        return cb({ login: false });
      }
    });
}

export function getToken() {
  return localStorage.jwtToken;
}

export function logout(cb) {
  delete localStorage.jwtToken;
  delete localStorage.expiration;
  delete localStorage.permissions;
  if (cb) cb();
}

export function loggedIn() {
  const jwtToken = localStorage.jwtToken;
  const expiration = localStorage.expiration;
  const cookies = new Cookies();

  if (!jwtToken || !expiration) {
    return false;
  }

  const env = process.env.REACT_APP_ENVIRONMENT || "stage";
  const isProd = env.includes("prod");

  const domain = isProd ? ".singu.com.br" : ".singu.io";

  cookies.set("auth_bo_token", localStorage.jwtToken, { domain });
  cookies.set("auth_bo_expiration", localStorage.expiration, { domain });
  cookies.set("auth_bo_user", localStorage.user, { domain });
  cookies.set("auth_bo_permissions", localStorage.permissions, { domain });

  const loggeId = jwtToken && moment(expiration) > moment();
  return loggeId;
}

export function userIsAdmin() {
  const user = JSON.parse(localStorage.getItem("user"));
  return user && user.type === "administrator";
}

export function userIsHead() {
  const user = JSON.parse(localStorage.getItem("user"));
  return user && user.type === "financeHead" || user.type === "operationHead";
}

export default { login, getToken, logout, loggedIn };
