import axios from 'axios';
import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];
import { getToken } from './../../models/auth';

export function fetchArtistBonus(id, page, limit) {
    return axios.get(`${BACKEND_URL}/specialists/${id}/payments?page=${page}&limit=${limit}`, {
        headers: { Authorization: `Bearer ${getToken()}` }
    });
}

export function fetchFile(id) {
    return axios.get(`${BACKEND_URL}/artist_bonus/${id}/fileNameSignedUrl`, {
        headers: { Authorization: `Bearer ${getToken()}` }
    });
}
