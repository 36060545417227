import axios from 'axios';
import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];

import { getToken } from '../../models/auth';

export function payBonusToArtist(professionalId, value, reason, description, scheduleId, scheduleDate, reasonText, file) {
  const formData = new FormData();

  formData.append("value", value);
  formData.append("reason", reason);
  formData.append("message", description);
  formData.append("scheduleId", scheduleId);
  formData.append("scheduleDate", scheduleDate);

  if (reasonText) {
    formData.append("reasonText", reasonText);
  }

  if (file) {
    formData.append("file", file);
  }

  return axios.post(`${BACKEND_URL}/specialists/${professionalId}/payment`,
    formData,
    {
      headers: { 
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "multipart/form-data"
      }
    }
  );
}

export function editBonusToArtist(transfer_id, professionalId, value, reason, description, idschedule, scheduleDate, reasonText, file) {
  let data = { professionalId, value, reason, description, idschedule, scheduleDate, file };
  if (reasonText) {
    data.reasonText = reasonText;
  }

  return axios.put(`${BACKEND_URL}/transfer/order/${transfer_id} `,
    data,
    {
      headers: { Authorization: `Bearer ${getToken()}` }
    }
  );
}