import React from "react";
import { Component } from "react";
import { Label } from "react-bootstrap";
import bonus from "../../helper/bonus";
import "./ArtistBonusHistory.css";
import moment from 'moment-timezone';
import ArtistBonus from "./ArtistTop/Modals/ArtistBonus";
import ArtistBonusStatus from "./ArtistBonusStatus";

const reasons = bonus.REASONS;

class ArtistBonusHistory extends Component {
  state = {
    bonus: [],
    currentPage: 1,
    itensPerPage: 3,
    isArtistBonusOpen: false,
    selectedBonus: null,
    isArtistBonusStatusOpen: null,
    history: [],
    fileViewer: null,
    isLoadingFile: {loading: false, id: null},
  };

  statusBonus = {
    1: { status: "Pendente head de operações", label: "info" },
    2: { status: "Pendente head financeiro", label: "info" },
    3: { status: "Aprovado", label: "success" },
    4: { status: "Recusado pelo head de operações", label: "danger" },
    5: { status: "Recusado pelo head financeiro", label: "danger" },
  }

  showArtistBonus = (selectedBonus) => {
    this.setState({ isArtistBonusOpen: true, selectedBonus });
  };

  closeArtistBonus = () => this.setState({ isArtistBonusOpen: false, selectedBonus: null });

  showBonusStatusHistoric = (history) => {
    this.setState({ isArtistBonusStatusOpen: true, history });
  };

  downloadFile = (id) => {
    if (this.state.isLoadingFile.loading) return;
    this.setState({ isLoadingFile: {loading: true, id} });
    this.props.fetchFile(id).then(({ data }) => {
      const link = data.data;
      window.open(link, '_blank'); // Abre o link em uma nova aba
    }).catch((err) => {
      console.error(err);
      alert("Erro ao recuperar o arquivo");
    }).finally(() => {
      this.setState({ isLoadingFile: false });
    });
  };

  closeBonusStatusHistoric = () => this.setState({ isArtistBonusStatusOpen: false, history: [] });

  componentWillReceiveProps({ bonus }) {
    if (bonus && bonus.length > 0) {
      this.setState({ bonus: bonus.reverse().filter(element => typeof element === 'object') });
    }
  }

  handleClick = (event) => {
    const page = Number(event.target.id);
    this.props.fetchArtistBonus(page, this.state.itensPerPage);
    this.setState({ currentPage: page });
  };
  render() {
    const { bonus, currentPage, itensPerPage, isArtistBonusOpen, isArtistBonusStatusOpen } = this.state;
    // Logic for displaying page numbers
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(this.props.totalItems / itensPerPage); i++) {
      pageNumbers.push(i);
    }

    const renderPageNumbers = pageNumbers.map(number => {
      return (
        <li
          key={number}
          id={number}
          className={currentPage === number ? 'currentPage' : ''}
          onClick={this.handleClick}
        >
          {number}
        </li>
      );
    });

    return (
      <div>
        <div id="artistComments">
          <div className="takeRateHistory-container">
            <h2 className="section-title">Pagamento manual</h2>

            <div className="container-flex">
              {bonus.length > 0 ? bonus.map((element, index) => {
                let reason = reasons.find(item => item.id === parseInt(element.reason));
                let statusInfo = this.statusBonus[element?.approval?.status];
                return (
                  <div className="card-bonus" key={index}>
                    <Label bsStyle={`${statusInfo ? statusInfo.label : ""} label-center`}>{statusInfo ? statusInfo.status : ""}</Label>
                    <p className="card-item-date"><strong>Data:</strong> {element.createdAt ? moment.tz(element.createdAt, 'America/Sao_Paulo').format('DD/MM/YY HH:mm') : 'N/A'} </p>
                    <p className="card-item"><strong>Responsável:</strong> {element.responsible ? element.responsible.name : 'N/A'}</p>
                    <p className="card-item"><strong>Motivo: </strong> {reason ? reason.description : 'N/A'}</p>
                    <p className="card-item"><strong>Valor:</strong> {element.value ? `R$ ${parseFloat(element.value / 100).toFixed(2)}` : 'N/A'}</p>
                    <p className="card-item"><strong>Descrição: </strong> {element.description ? element.description : 'N/A'}</p>
                    <p className="card-item"><strong>Status do pagamento: </strong> {element?.transfer?.status ? element.transfer.status : 'N/A'}</p>
                    <div className="card-bonus-button">
                      <p className="takeRate-creat"><strong>TRA: </strong> {element.transfer && element.transfer.id ? element.transfer.id : 'N/A'}</p>
                      <div className="button-container">
                        {(element?.approval?.status == 4 || element?.approval?.status == 5) && (
                          <button variant="primary" title="Editar bônus" className="btn btn-info" onClick={() => this.showArtistBonus(element)}>
                            <span className="glyphicon glyphicon-edit"></span>
                          </button>
                        )}
                        {element?.approval?.history && (
                          <button variant="primary" title="Histórico bônus" className="btn btn-info" onClick={() => this.showBonusStatusHistoric(element?.approval?.history)}>
                            <span className="glyphicon glyphicon-info-sign"></span>
                          </button>
                        )}
                        {element?.fileName && (
                          <button variant="primary" title="PDF" className="btn btn-info" onClick={() => this.downloadFile(element?._id)}>
                            {(this.state.isLoadingFile.loading && this.state.isLoadingFile.id === element._id) ?
                              <span className="spinner glyphicon glyphicon-refresh" ></span> 
                              : <span className="glyphicon glyphicon-file"></span>}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                );
              }) : <p className="text-center">Sem histórico de pagamentos manuais</p>}
            </div>

            <div>
              <ul id="page-numbers" className="page-numbers page-bonus">
                {renderPageNumbers}
              </ul>
            </div>
          </div>
        </div>
        <ArtistBonus
          open={isArtistBonusOpen}
          closeArtistBonus={this.closeArtistBonus}
          artist={this.props.artist}
          bonus={this.state.selectedBonus}
          editBonus={this.props.editBonus}
        />
        <ArtistBonusStatus
          open={isArtistBonusStatusOpen}
          closeBonusStatus={this.closeBonusStatusHistoric}
          historyBonus={this.state.history}
        />
      </div>
    )
  }
}

export default ArtistBonusHistory;