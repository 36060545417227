import { Map, GoogleApiWrapper } from "google-maps-react";
import React, { Component, PropTypes } from "react";
import { Polygon } from "google-maps-react/dist/components/Polygon";
import { connect } from "react-redux";
import { fetchPolygons } from "./../../actions/dynamic_prices/fetch_polygons";
import { fetchAreasService } from "../../actions/areas/fetch_areas";
import { updateDynamicPrice } from '../../actions/dynamic_prices/update_dynamic_price'
import Swal from "sweetalert2";
import { Label, Button, Alert } from 'react-bootstrap'
import Loading from "../../components/Global/Loading/Loading";
import { updateDynamicPrices } from "../../actions/dynamic_prices/update_dynamic_prices";

const polygonsReferences = {
  realtime: false,
  "super now fixo": false,
  "super now flex": false,
  "now fixo": false,
  "now flex": false,
  "programado fixo": false,
  "programado flex": false,
};

const emptyFormState = {
  active: false,
  pixPayment: false,
  range: {
    realtime: false,
    "super now fixo": false,
    "super now flex": false,
    "now fixo": false,
    "now flex": false,
    "programado fixo": false,
    "programado flex": false,
  },
  option: ''
}
class PolygonMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedArea: {},
      selectedService: {},
      selectedPolygons: [],
      multiselect: false,
      loadingPolygons: false,
      polygons: [],
      areas: [],
      formState: emptyFormState
    };

    this.mapRef = null;
  }

  componentDidMount() {
    this.loadAreas();
  }

  loadAreas = async () => {
    const { data: areas } = await fetchAreasService()

    this.setState({ areas });
  }

  loadPolygons = async () => {
    try {
      const { selectedArea, selectedService } = this.state;

      const isServiceEmpty = Object.keys(selectedService).length === 0;

      if (!selectedArea._id || isServiceEmpty) {
        return Swal.fire({
          icon: "info",
          text: "Por favor, selecione uma cidade e um serviço"
        });
      }

      this.setState({
        loadingPolygons: true,
        polygons: [],
        selectedPolygons: []
      });

      const { payload } = await this.props.fetchPolygons(this.state.selectedService._id, this.state.selectedArea._id);

      this.setState({ fetchedPolygons: true });

      this.insertPolygons(payload.data);
    } catch (err) {
      console.log(err);
      Swal.fire({
        icon: "error",
        title: "Ops...",
        text: "Houve um erro ao buscar os polígonos.\nPor favor, tente novamente."
      });
    } finally {
      this.setState({ loadingPolygons: false });
    }
  };

  changeSelectedService = event => {
    const { selectedArea } = this.state;

    // Procura nas categorias o serviço para completar o estado com suas
    // informações.
    selectedArea.categories.forEach(category => {
      category.services.forEach(service => {
        if (service._id === event.target.value) {
          this.setState({
            selectedService: service,
          });
        }
      });
    });
  };

  changeSelectedArea = event => {
    const { areas } = this.state;

    areas.forEach(area => {
      if (area._id === event.target.value) {
        return this.setState({ selectedArea: area });
      }
    })
  };

  insertPolygons(polygons) {
    try {
      const { fetchedPolygons } = this.state;
      if (!fetchedPolygons) return;

      const _polygons = polygons && polygons.map((item) => {
        let polygonPoints;
        if (item.polygon.location.coordinates) {
          polygonPoints = item.polygon.location.coordinates[0].map(polygonLatLng => {
            return {
              lat: Number(polygonLatLng[1]),
              lng: Number(polygonLatLng[0])
            };
          });
        }
        if (item.polygon.location.geometries) {
          const geometriesArray = item.polygon.location.geometries.map(allGeometries => {
            return allGeometries.coordinates[0].map(polygonLatLng => {
              return {
                lat: Number(polygonLatLng[1]),
                lng: Number(polygonLatLng[0])
              };
            });
          });
          polygonPoints = geometriesArray.flat();
        }

        const color = this.normalizePolygonColor(item);

        return (
          <Polygon
            reference={this}
            key={item._id}
            coordinates={JSON.stringify(item.location)}
            rule={item.rule}
            polygonId={item.polygon._id}
            active={item.active}
            pixPayment={item.pixPayment || false}
            paths={polygonPoints}
            name={item.polygon.name}
            _id={item._id}
            serviceId={item.serviceId}
            strokeColor={"#000000"}
            strokeOpacity={0.8}
            strokeWeight={1}
            fillColor={color}
            fillOpacity={0.55}
            onClick={this.selectPolygon.bind(this)}
          />
        );
      });

      this.setState({ polygons: _polygons });
    } catch (err) {
      console.log(err)
      alert('Erro ao inserir os polígonos!')
    } finally {
      this.setState({
        loadingPolygons: false
      });
    }
  }

  selectPolygon(props, item) {
    const { multiselect, selectedPolygons } = this.state;
    const { rule } = item;
    const ranges = rule.range;

    let _selectedPolygons = selectedPolygons;

    const isPolygonSelected = _selectedPolygons.find(polygon => polygon._id === item._id);

    // Caso ja esteja selecionado
    if (isPolygonSelected) {
      item.setOptions({
        fillColor: this.normalizePolygonColor(item)
      });

      const newSelectedPolygons = _selectedPolygons.filter(polygon => polygon._id !== item._id);
      // Se deletar elemento e ainda sobrar outro/s é multiselect. Se não, não.
      return this.setState({
        multiselect: newSelectedPolygons.length === 0 ? false : true,
        formState: emptyFormState,
        selectedPolygons: newSelectedPolygons
      })
    }

    Object.assign(item, {
      polygonRanges: {
        ...item.polygonRanges,
        ...polygonsReferences
      }
    })

    for (let count in ranges) {
      let range = ranges[count];
      if (item.polygonRanges[range.reference] !== undefined) {
        item.polygonRanges[range.reference] = true;
      }
    }

    if (rule.realtime) item.polygonRanges['realtime'] = true;

    item.setOptions({ fillColor: "#d426ff" });

    //Inserir elemento e mostrar seus valores nas checkbox
    if (selectedPolygons.length === 0 && !multiselect) {
      return this.setState({
        multiselect: false,
        formState: {
          active: item.active,
          pixPayment: item.pixPayment,
          range: item.polygonRanges
        },
        selectedPolygons: [item]
      })
    }

    // Inserir o elemento com os outros ja selecionados e checkbox vazias
    _selectedPolygons.push(item);

    return this.setState({
      multiselect: true,
      formState: emptyFormState,
      selectedPolygons: _selectedPolygons
    })
  }

  normalizePolygonColor = (polygon) => {
    let color = "#0000FF";

    if (polygon.rule.realtime) {
      color = "#ffa500";
    } else if (polygon.rule.range.length > 4) {
      color = "#FFFF00";
    } else if (polygon.rule.range.length > 2) {
      color = "#00FF00";
    } else if (polygon.rule.range.length == 0) {
      color = "#939393";
    }

    if (polygon.active === false) {
      color = "#e83e27"
    }

    return color;
  }

  handlePolygonLabelClick = (polygonId) => {
    const { selectedPolygons } = this.state;

    const polygon = selectedPolygons.find(polygon => {

      if (polygon._id !== polygonId) return false;

      return polygon;
    });

    if (!polygon) return;

    // eslint-disable-next-line no-undef
    this.selectPolygon(_, polygon, _)
  }

  clearAllPolygons = () => {
    const { selectedPolygons } = this.state;

    try {
      selectedPolygons.forEach(sp => {
        sp.setOptions({ fillColor: this.normalizePolygonColor(sp) });
      })

      this.setState({
        selectedPolygons: [],
        multiselect: false
      });
    } catch (err) {
      console.error(err)
      alert('Erro ao limpar os polígonos.')
    }
  }

  handleFormSubmit = async (event, option) => {
    try {
      event.preventDefault();

      let updatePricePayload = null

      if (option === "range") {
        const superNowFixoPrice = parseInt(event.target.elements.superNowFixoPrice.value);
        const superNowFlexPrice = parseInt(event.target.elements.superNowFlexPrice.value);
        const nowFixoPrice = parseInt(event.target.elements.nowFixoPrice.value);
        const nowFlexPrice = parseInt(event.target.elements.nowFlexPrice.value);
        const programadoFixoPrice = parseInt(event.target.elements.programadoFixoPrice.value);
        const programadoFlexPrice = parseInt(event.target.elements.programadoFlexPrice.value);

        updatePricePayload = JSON.stringify({
          serviceId: this.state.selectedService._id,
          areaId: this.state.selectedArea._id,
          polygonId: this.state.selectedPolygons[0]?.polygonId,
          prices: [
            {
              reference: "super now fixo",
              beginAt: this.state.selectedPolygons[0]?.rule?.range?.filter(range => range.reference === "super now fixo")[0]?.beginAt,
              price: superNowFixoPrice,
              beginAtRange: this.state.selectedPolygons[0]?.rule?.range?.filter(range => range.reference === "super now fixo")[0]?.beginAtRange,
              highlightImage: this.state.selectedPolygons[0]?.rule?.range?.filter(range => range.reference === "super now fixo")[0]?.highlightImage,
              color: this.state.selectedPolygons[0]?.rule?.range?.filter(range => range.reference === "super now fixo")[0]?.color
            },
            {
              reference: "super now flex",
              beginAt: this.state.selectedPolygons[0]?.rule?.range?.filter(range => range.reference === "super now flex")[0]?.beginAt,
              price: superNowFlexPrice,
              beginAtRange: this.state.selectedPolygons[0]?.rule?.range?.filter(range => range.reference === "super now flex")[0]?.beginAtRange,
              highlightImage: this.state.selectedPolygons[0]?.rule?.range?.filter(range => range.reference === "super now flex")[0]?.highlightImage,
              color: this.state.selectedPolygons[0]?.rule?.range?.filter(range => range.reference === "super now flex")[0]?.color
            },
            {
              reference: "now fixo",
              beginAt: this.state.selectedPolygons[0]?.rule?.range?.filter(range => range.reference === "now fixo")[0]?.beginAt,
              price: nowFixoPrice,
              beginAtRange: this.state.selectedPolygons[0]?.rule?.range?.filter(range => range.reference === "now fixo")[0]?.beginAtRange,
              highlightImage: this.state.selectedPolygons[0]?.rule?.range?.filter(range => range.reference === "now fixo")[0]?.highlightImage,
              color: this.state.selectedPolygons[0]?.rule?.range?.filter(range => range.reference === "now fixo")[0]?.color
            },
            {
              reference: "now flex",
              beginAt: this.state.selectedPolygons[0]?.rule?.range?.filter(range => range.reference === "now flex")[0]?.beginAt,
              price: nowFlexPrice,
              beginAtRange: this.state.selectedPolygons[0]?.rule?.range?.filter(range => range.reference === "now flex")[0]?.beginAtRange,
              highlightImage: this.state.selectedPolygons[0]?.rule?.range?.filter(range => range.reference === "now flex")[0]?.highlightImage,
              color: this.state.selectedPolygons[0]?.rule?.range?.filter(range => range.reference === "now flex")[0]?.color
            },
            {
              reference: "programado fixo",
              beginAt: this.state.selectedPolygons[0]?.rule?.range?.filter(range => range.reference === "programado fixo")[0]?.beginAt,
              price: programadoFixoPrice,
              beginAtRange: this.state.selectedPolygons[0]?.rule?.range?.filter(range => range.reference === "programado fixo")[0]?.beginAtRange,
              highlightImage: this.state.selectedPolygons[0]?.rule?.range?.filter(range => range.reference === "programado fixo")[0]?.highlightImage,
              color: this.state.selectedPolygons[0]?.rule?.range?.filter(range => range.reference === "programado fixo")[0]?.color
            },
            {
              reference: "programado flex",
              beginAt: this.state.selectedPolygons[0]?.rule?.range?.filter(range => range.reference === "programado flex")[0]?.beginAt,
              price: programadoFlexPrice,
              beginAtRange: this.state.selectedPolygons[0]?.rule?.range?.filter(range => range.reference === "programado flex")[0]?.beginAtRange,
              highlightImage: this.state.selectedPolygons[0]?.rule?.range?.filter(range => range.reference === "programado flex")[0]?.highlightImage,
              color: this.state.selectedPolygons[0]?.rule?.range?.filter(range => range.reference === "programado flex")[0]?.color
            },
          ]
        });
      }


      const {
        selectedPolygons,
        formState,
        multiselect,
        selectedService,
      } = this.state;

      if (selectedPolygons.length > 0 && multiselect) {
        const polygonsHTML = selectedPolygons.reduce((total, sp) => {
          return total += `
            <div style="
              display: inline-block;
              background: #ddd;
              padding: 3px 5px;
              border-radius: 3px;
              font-size: 14px;
              font-weight: bold;
              margin: 3px
            ">
              ${sp.name}
            </div>
          `
        }, '');

        let activateItens = '';
        let disableItens = '';

        for (const range in formState.range) {
          const rangeHTML = `
            <div style="
            display: inline-block;
            background: #ddd;
            padding: 3px 5px;
            border-radius: 3px;
            font-size: 14px;
            font-weight: bold;
            margin: 3px">
              ${range}
            </div>
          `;

          if (formState.range[range]) {
            activateItens += rangeHTML
          } else {
            disableItens += rangeHTML
          }
        }

        const rangesHTML = `
          <h3>Modalidades alteradas</h3>
          ${activateItens.length > 0 ? '<h4>Ativar</h4>' : ''}
          ${activateItens}
          ${disableItens.length > 0 ? '<h4>Desativar</h4>' : ''}
          ${disableItens}
        `;

        const alertHTML = `
          <div>
            <h4>
              Você está atualizando mais de uma região. Essa atualização afetará todas as regiões selecionadas e não poderá ser desfeita.
            </h4>
            <h3>Regiões</h3>
            ${polygonsHTML}
            ${option === 'range' ? rangesHTML : ''}
          </div>
        `;

        const { isConfirmed } = await Swal.fire({
          title: 'Voce tem certeza?',
          html: alertHTML,
          footer: 'Para sair do modo de selecionamento múltiplo deselecione todos e selecione apenas um.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim, atualizar!'
        });

        if (!isConfirmed) return;
      }

      this.setState({
        formState: {
          ...formState,
          option: option,
        }
      })

      const polygonsIds = selectedPolygons.map(sp => sp._id);

      // Manter option como primeiro parametro do objeto
      const data = {}

      if (option === 'range') {
        data['rule'] = {
          range: formState['range'],
          realtime: formState['range'].realtime
        }
      } else {
        data[option] = formState[option];
      }

      Object.assign(data, {
        serviceId: selectedService._id,
        polygonsIds
      });

      await updateDynamicPrice(data);
      if (updatePricePayload) {
        await updateDynamicPrices(JSON.parse(updatePricePayload));
      }

      // Atualiza Client
      // const updatedPolygons = polygons.map(polygon => {
      //   let _polygon = { ...polygon };
      //   let polygonProps = _polygon.props;

      //   const isPolygonUpdated = polygonsIds.includes(polygonProps._id);

      //   if(!isPolygonUpdated) return _polygon;

      //   console.log(polygonProps.reference)

      //   if(option === 'range') {
      //     const { realtime, ...range} = formState.range

      //     // polygonProps['rule']['range'] = range;
      //     // if(realtime) polygonProps['rule']['realtime'] = realtime;

      //     const newRule = { ...polygonProps.rule };
      //     newRule['range'] = range;
      //     if(realtime) newRule['realtime'] = realtime;

      //     polygon.setOptions({ rule: newRule })

      //     console.log('updated range', polygon);
      //     return polygon;
      //   } else {
      //     polygon.setOptions({ [option]: formState[option] })
      //     console.log('updated boolean', polygon);
      //     return polygon;
      //   }
      // })

      await Swal.fire({
        icon: 'success',
        text: 'Polígono/s alterado/s com sucesso.'
      });

      this.loadPolygons();
    } catch (err) {
      console.error('FormSubmit', err);

      if (err.data && err.data.error && err.data.error.description) {
        return alert(err.data.error.description);
      }

      alert('Não foi possível alterar o/s polígono/s. Por favor, contate a equipe de suporte.')
    } finally {
      this.setState({
        formState: {
          ...this.state.formState,
          option: null,
        }
      })
    }
  }

  updateFormState = (event) => {
    const { formState } = this.state;
    const option = event.target.name;

    const groupRanges = {
      "super now": ["super now fixo", "super now flex"],
      "now": ["now fixo", "now flex"],
      "programado": ["programado fixo", "programado flex"],
      "realtime": ["realtime"]
    };

    Object.keys(formState).forEach(parentKey => {
      if (typeof formState[parentKey] === 'object') {
        Object.keys(formState[parentKey]).forEach(childrenKey => {
          groupRanges[option]?.map(range => {
            if (childrenKey === range) {
              formState[parentKey][childrenKey] = !formState[parentKey][childrenKey];
            }
          });
        })
      } else {
        if (parentKey === option) {
          formState[parentKey] = !formState[parentKey];
        }
      }
    });

    this.setState({
      formState: formState
    });
  };

  renderServiceArea() {
    const {
      loadingPolygons,
      selectedService,
      selectedPolygons,
      multiselect,
      fetchedPolygons,
      formState
    } = this.state;

    if (loadingPolygons) return <Loading />;

    const setRangeComplete = (range) => {
      const groupRanges = {
        "super now": ["super now fixo", "super now flex"],
        "now": ["now fixo", "now flex"],
        "programado": ["programado fixo", "programado flex"],
        "realtime": ["realtime"]
      };

      // eslint-disable-next-line no-prototype-builtins
      if (groupRanges.hasOwnProperty(range)) {
        const subRanges = groupRanges[range];
        // Verifica se pelo menos um dos subitens do grupo é verdadeiro
        return subRanges.some(subRange => formState['range'][subRange]);
      }

      // Caso contrário, verifica o subitem específico
      return formState['range'][range];
    }

    const setOptionComplete = (option) => {
      return formState[option];
    }

    return (
      <div className="col-xs-12" style={{ marginTop: '20px', marginBottom: '30px' }}>
        <div className="row">
          <div className="col-md-6" style={{ height: '600px' }}>
            <Map
              ref={ref => this.mapRef = ref}
              google={this.props.google}
              style={{
                width: '100%',
                height: '100%'
              }}

              initialCenter={this.state.selectedArea && this.state.selectedArea.location || { "lat": -23.6815315, "lng": -46.8754951 }
              }
              zoom={11}
            >
              {this.state.polygons}
            </Map>
          </div>
          {fetchedPolygons ? (
            <div className="col-md-6">
              <div className="col-xs-12">
                <div className="row">
                  {selectedService._id ? (
                    <div className="col-xs-12" style={{ display: 'flex', alignItems: 'center' }}>
                      <h3 style={{
                        fontWeight: 'bold',
                        marginTop: 0,
                        marginBottom: 0,
                        marginRight: '5px'
                      }}>{selectedService.name["pt-BR"]}</h3>
                      <Label bsStyle="default" style={{ marginRight: '5px', fontSize: '12px' }}>
                        {selectedService._id}
                      </ Label>
                    </div>
                  ) : (
                    <div className="col-xs-12">
                      <h4>Selecione um serviço</h4>
                    </div>
                  )}
                  {selectedService._id && selectedPolygons.length > 0 ? (
                    <div className="col-xs-12" style={{ marginTop: "12px" }}>
                      <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                        <h4 style={{ fontWeight: "bold", marginBottom: 0, marginTop: 0, marginRight: "8px" }} >
                          {multiselect ? "Polígonos selecionados:" : "Polígono selecionado:"}
                        </h4>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          maxWidth: "350px",
                          maxHeight: "100px",
                          overflowY: "auto",
                        }}
                      >
                        {selectedPolygons.map(polygon => (
                          <Button
                            key={polygon._id}
                            style={{
                              marginRight: '5px',
                              marginTop: '12px',
                              paddingLeft: "16px",
                              paddingRight: "16px",
                              backgroundColor: "#FF7A66",
                              fontWeight: "bold",
                              color: "#FFF",
                              border: "0px"
                            }}
                            onClick={() => this.handlePolygonLabelClick(polygon._id)}
                          >
                            {polygon.name}
                          </Button>
                        ))}
                      </div>
                      <button
                        className="btn btn-sm btn-default"
                        onClick={() => this.clearAllPolygons()}
                        style={{
                          padding: "3px 5px",
                          marginRight: "5px",
                          marginTop: "12px"
                        }}
                      >
                        Limpar todos
                      </button>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center"
                        }}
                      >
                        <div
                          style={{
                            marginTop: '32px',
                            maxWidth: "200px",
                            minWidth: "200px",
                            borderRight: "1px solid #D9D9D9"
                          }}
                        >
                          <div>
                            <form onSubmit={(e) => this.handleFormSubmit(e, 'active')}>
                              <h4 style={{ fontWeight: 'bold' }}>Status:</h4>
                              <div style={{ marginTop: '12px' }}>
                                <span style={{ marginRight: '10px' }}>
                                  <input
                                    style={{ marginLeft: "0px !important" }}
                                    type="checkbox"
                                    name={"active"}
                                    onChange={this.updateFormState.bind(this)}
                                    checked={setOptionComplete("active")}
                                  />{" "}
                                  Ativo
                                </span>
                              </div>
                              <button
                                type="submit"
                                className="btn btn-sm btn-success"
                                style={{ marginTop: '10px' }}>
                                {formState.option === 'active' ? 'Salvando...' : 'Salvar status'}
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          marginTop: '32px',
                        }}
                      >
                      </div>
                    </div>
                  ) : (
                    <div className="col-xs-9">
                      {selectedService._id && (
                        <Alert bsStyle="info" style={{ marginTop: '12px' }}>
                          Selecione uma <strong>região</strong>!
                        </Alert>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="col-md-6">
              <div className="col-xs-9">
                <Alert
                  bsStyle="info">
                  Busque por um <strong>serviço</strong> em alguma <strong>área</strong>.
                </Alert>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  render() {
    const { loading, areas, selectedArea } = this.state;

    if (loading) return (
      <div>
        <h4> Carregando o Mapa. </h4>
      </div>
    )

    return (
      <div className="row">
        <div className="col-xs-12">
          <h1>Area de Atuação</h1>
        </div>
        <div className="col-md-12 col-lg-10">
          <div className="row">
            <div className="col-md-3">
              <h4>Selecione uma área</h4>
              <select
                style={{ width: "100%" }}
                className="form-control"
                name=""
                id=""
                onChange={this.changeSelectedArea}>
                <option value="">Áreas</option>
                {areas.map(area => {
                  if (area.active) return <option key={area._id} value={area._id}>{area.city}</option>
                })}
              </select>
            </div>
            <div className="col-md-6">
              <h4>Selecione um Serviço</h4>
              <select
                style={{ width: "100%" }}
                onChange={this.changeSelectedService}
                className={"form-control"}>
                <option value="">{selectedArea._id ? "Selecione um serviço" : "Primeiro, selecione uma área"}</option>
                {
                  selectedArea._id && selectedArea.categories.map(category => {
                    return category.services.map(service => {
                      return <option key={service._id} value={service._id}>{category.name["pt-BR"]} - {service.name["pt-BR"]}</option>
                    })
                  })
                }
              </select>
            </div>
            <div className="col-md-3">
              <h4>&nbsp;</h4>
              <button className="btn btn-success" onClick={this.loadPolygons}>
                Buscar
              </button>
            </div>
          </div>
        </div>
        {this.renderServiceArea()}
      </div>
    );
  }
}

PolygonMap.propTypes = {
  polygon: PropTypes.array
};

PolygonMap.contextTypes = {
  router: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    dynamicPrices: state.dynamicPrices,
    polygons: state.all
  };
}

export default connect(mapStateToProps, {
  fetchPolygons
})(
  GoogleApiWrapper({
    apiKey: "AIzaSyBT3vNYVkejeaHoro4DikXez6z7GACBBak",
  })(PolygonMap)
);
