import React, { Component } from "react";
import "./ArtistInfo.css";

import Top from "../../components/Artists/ArtistTop";
import Infos from "../../components/Artists/ArtistInfos";
import Comments from "../Comments/Comments";
import TakeRate from "../../components/Artists/ArtistTakeRate";
import ArtistBonusHistory from "../../components/Artists/ArtistBonusHistory";

// actions
import { fetchArtist } from "./../../actions/artists/fetch_artist";
import { fetchService } from "./../../actions/service/fetch_service";
import { payBonusToArtist, editBonusToArtist } from "./../../actions/artists/pay_bonus_to_artist";
import { fetchArtistBonus, fetchFile } from "./../../actions/artists/fetch_artist_bonus";
import { fetchArtistTakeRateHistory } from "./../../actions/artists/fetch_artist_takeratehistory";
import { fetchArtistGamificationPerformance } from "./../../actions/artists/fetch_artist_gamification";
import { addPointsToArtist } from "./../../actions/artists/add_points_gamification";
class ArtistsInfo extends Component {
  state = {
    artist: {},
    bonus: [],
    bonusTotalItems: 0,
    gamification: {}
  };

  componentWillMount() {
    if (this.props.params && this.props.params.artistId) {
      fetchArtist(this.props.params.artistId)
        .payload.then(response => {
          const { data } = response;
          this.setState({ artist: data }, () => {
            this.fetchArtistBonusInfo(1, 3);
            this.fetchArtistTakeRateHistoryInfo(1, 3);
            this.fetchArtistGamificationInfo();
            this.fetchAllServices();
          });
        })
        .catch(error => {
          console.log(error);
          alert("Erro ao recuperar os dados do servidor");
        });
    } else {
      alert("Id da profissional obrigatorio");
    }
  }

  fetchAllServices = () => {
    fetchService().then(({ data }) => {
      const result = {};
      data.forEach(service => {
        result[service.serviceId] = service.name
      })

      this.setState({ services: result })
    })
  }

  payBonus = ({ value, reason, description, scheduleId, scheduleDate, reasonText, file }) => {
    return payBonusToArtist(this.state.artist._id, value, reason, description, scheduleId, scheduleDate, reasonText, file);
  };


  editBonus = ({ transferId, value, reason, description, scheduleId, scheduleDate, reasonText, file }) => {
    return editBonusToArtist(transferId, this.state.artist._id, value, reason, description, scheduleId, scheduleDate, reasonText, file);
  };

  addPointsGamification = ({ points, reason }) => {
    return addPointsToArtist(this.state.artist._id, points, reason);
  }

  fetchArtistBonusInfo = (page, limit) => {
    if (this.props.params && this.props.params.artistId) {
      fetchArtistBonus(this.props.params.artistId, page, limit)
        .then(response => {
          this.setState({
            bonus: response.data.data,
            bonusTotalItems: response.data.totalItems
          });
        })
        .catch(error => {
          console.log(error);
          alert("Erro ao coletar os dados de pagamento de bonus");
        });
    }
  };

  fetchArtistTakeRateHistoryInfo = (page, limit) => {
    if (this.props.params && this.props.params.artistId) {
      fetchArtistTakeRateHistory(this.props.params.artistId, page, limit)
        .then(response => {
          this.setState({
            takeRateHistory: response.data.takeRateHistory,
            takeRateHistoryTotalItems: response.data.totalItems
          });
        })
        .catch(error => {
          console.log(error);
          alert("Erro ao coletar os dados de Take Rate");
        });
    }
  };

  fetchArtistGamificationInfo = () => {
    if (this.props.params && this.props.params.artistId) {
      Promise.all([
        fetchArtistGamificationPerformance(this.props.params.artistId)
      ])
        .then(response => {
          this.setState({
            gamification: response[0].data
          });
        })
        .catch(error => {
          console.error("Erro ao coletar os dados do gamification", error);
        });
    }
  };

  render() {
    if (!this.state.artist._id) {
      return (
        <div className={"col-xs-12"}>
          <p>Por favor, aguarde....</p>
        </div>
      )
    }
    return (
      <div className="artist-info">
        <Top
          payBonus={this.payBonus}
          artist={this.state.artist}
          services={this.state.services}
          gamification={this.state.gamification}
          addPointsGamification={this.addPointsGamification}
          getIdSchedulesBonus={this.getIdSchedulesBonus}
        />
        <ArtistBonusHistory
          editBonus={this.editBonus}
          bonus={this.state.bonus}
          artist={this.state.artist}
          totalItems={this.state.bonusTotalItems}
          fetchArtistBonus={this.fetchArtistBonusInfo}
          fetchFile={fetchFile}
        />
        <Comments comments={this.state.artist.comments || []} />
        <TakeRate
          totalItems={this.state.takeRateHistoryTotalItems}
          takeRateHistory={this.state.takeRateHistory}
          fetchTakeRateHistory={this.fetchArtistTakeRateHistoryInfo}
        />
        <Infos artist={this.state.artist} />
      </div>
    );
  }
}

export default ArtistsInfo;
